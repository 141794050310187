'use client';

import { Loader2, LogOut } from 'lucide-react';
import { useTransition } from 'react';
import { useTranslations } from 'next-intl';
import { logout } from './actions';
export default function LogoutButton() {
  const t = useTranslations('auth');
  const [isPending, startTransition] = useTransition();
  const onLogoutClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    startTransition(async () => {
      await logout();
    });
  };
  return <button onClick={onLogoutClick} className={'flex items-center w-full text-red'} data-sentry-component="LogoutButton" data-sentry-source-file="LogoutButton.tsx">
      <LogOut className="mr-2 h-4 w-4" data-sentry-element="LogOut" data-sentry-source-file="LogoutButton.tsx" />
      {isPending ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : t('logout')}
    </button>;
}